function NameOnly({ className }) {
  return (
    <svg
      className={className}
      width="170"
      height="53"
      viewBox="0 0 170 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Toro TMS</title>
      <g filter="url(#filter0_bd_280_2557)">
        <path
          d="M27.1307 3.5633V5.3613H23.554V17H21.35V5.3613H17.754V3.5633H27.1307Z"
          fill="currentColor"
        />
        <path
          d="M42.4515 17.1353C41.2012 17.1353 40.0477 16.8453 38.9908 16.2653C37.9468 15.6724 37.1155 14.854 36.4968 13.81C35.891 12.7531 35.5881 11.5673 35.5881 10.2526C35.5881 8.93797 35.891 7.75863 36.4968 6.71463C37.1155 5.67063 37.9468 4.85863 38.9908 4.27863C40.0477 3.68574 41.2012 3.3893 42.4515 3.3893C43.7146 3.3893 44.8681 3.68574 45.9121 4.27863C46.969 4.85863 47.8003 5.67063 48.4061 6.71463C49.0119 7.75863 49.3148 8.93797 49.3148 10.2526C49.3148 11.5673 49.0119 12.7531 48.4061 13.81C47.8003 14.854 46.969 15.6724 45.9121 16.2653C44.8681 16.8453 43.7146 17.1353 42.4515 17.1353ZM42.4515 15.2213C43.3408 15.2213 44.1335 15.0215 44.8295 14.622C45.5255 14.2095 46.0668 13.6295 46.4535 12.882C46.853 12.1215 47.0528 11.2451 47.0528 10.2526C47.0528 9.26019 46.853 8.39019 46.4535 7.64263C46.0668 6.89508 45.5255 6.32152 44.8295 5.92197C44.1335 5.52241 43.3408 5.32263 42.4515 5.32263C41.5621 5.32263 40.7695 5.52241 40.0735 5.92197C39.3775 6.32152 38.8297 6.89508 38.4301 7.64263C38.0435 8.39019 37.8501 9.26019 37.8501 10.2526C37.8501 11.2451 38.0435 12.1215 38.4301 12.882C38.8297 13.6295 39.3775 14.2095 40.0735 14.622C40.7695 15.0215 41.5621 15.2213 42.4515 15.2213Z"
          fill="currentColor"
        />
        <path
          d="M65.4229 17L62.3296 11.6253H60.6476V17H58.4436V3.5633H63.0836C64.1147 3.5633 64.9847 3.74374 65.6936 4.10463C66.4154 4.46552 66.9503 4.94885 67.2983 5.55463C67.6592 6.16041 67.8396 6.83708 67.8396 7.58463C67.8396 8.46108 67.5818 9.26019 67.0663 9.98197C66.5636 10.6909 65.7838 11.1742 64.7269 11.432L68.0523 17H65.4229ZM60.6476 9.86597H63.0836C63.9085 9.86597 64.5272 9.65974 64.9396 9.2473C65.3649 8.83485 65.5776 8.28063 65.5776 7.58463C65.5776 6.88863 65.3714 6.3473 64.9589 5.96063C64.5465 5.56108 63.9214 5.3613 63.0836 5.3613H60.6476V9.86597Z"
          fill="currentColor"
        />
        <path
          d="M83.7478 17.1353C82.4975 17.1353 81.344 16.8453 80.2871 16.2653C79.2431 15.6724 78.4118 14.854 77.7931 13.81C77.1873 12.7531 76.8844 11.5673 76.8844 10.2526C76.8844 8.93797 77.1873 7.75863 77.7931 6.71463C78.4118 5.67063 79.2431 4.85863 80.2871 4.27863C81.344 3.68574 82.4975 3.3893 83.7478 3.3893C85.0109 3.3893 86.1644 3.68574 87.2084 4.27863C88.2653 4.85863 89.0966 5.67063 89.7024 6.71463C90.3082 7.75863 90.6111 8.93797 90.6111 10.2526C90.6111 11.5673 90.3082 12.7531 89.7024 13.81C89.0966 14.854 88.2653 15.6724 87.2084 16.2653C86.1644 16.8453 85.0109 17.1353 83.7478 17.1353ZM83.7478 15.2213C84.6371 15.2213 85.4298 15.0215 86.1258 14.622C86.8218 14.2095 87.3631 13.6295 87.7498 12.882C88.1493 12.1215 88.3491 11.2451 88.3491 10.2526C88.3491 9.26019 88.1493 8.39019 87.7498 7.64263C87.3631 6.89508 86.8218 6.32152 86.1258 5.92197C85.4298 5.52241 84.6371 5.32263 83.7478 5.32263C82.8584 5.32263 82.0658 5.52241 81.3698 5.92197C80.6738 6.32152 80.126 6.89508 79.7264 7.64263C79.3398 8.39019 79.1464 9.26019 79.1464 10.2526C79.1464 11.2451 79.3398 12.1215 79.7264 12.882C80.126 13.6295 80.6738 14.2095 81.3698 14.622C82.0658 15.0215 82.8584 15.2213 83.7478 15.2213Z"
          fill="currentColor"
        />
        <path
          d="M107.744 3.48596V4.6073H103.993V17H102.64V4.6073H98.8699V3.48596H107.744Z"
          fill="currentColor"
        />
        <path
          d="M130.046 3.65996V17H128.692V6.3473L123.936 17H122.95L118.194 6.38597V17H116.841V3.65996H118.272L123.434 15.2213L128.596 3.65996H130.046Z"
          fill="currentColor"
        />
        <path
          d="M144.174 17.1353C143.285 17.1353 142.499 16.9806 141.816 16.6713C141.145 16.362 140.617 15.9302 140.23 15.376C139.844 14.8217 139.637 14.2031 139.612 13.52H141.042C141.107 14.1773 141.397 14.7573 141.912 15.26C142.428 15.7497 143.182 15.9946 144.174 15.9946C145.089 15.9946 145.811 15.7626 146.34 15.2986C146.881 14.8217 147.152 14.2224 147.152 13.5006C147.152 12.9206 147.003 12.4566 146.707 12.1086C146.41 11.7477 146.043 11.4835 145.605 11.316C145.167 11.1355 144.561 10.9422 143.788 10.736C142.885 10.4911 142.17 10.2526 141.642 10.0206C141.113 9.78863 140.662 9.42774 140.288 8.93797C139.914 8.44819 139.728 7.78441 139.728 6.94663C139.728 6.25063 139.908 5.63197 140.269 5.09063C140.63 4.53641 141.139 4.10463 141.796 3.7953C142.454 3.48597 143.208 3.3313 144.058 3.3313C145.308 3.3313 146.314 3.64063 147.074 4.2593C147.848 4.86508 148.292 5.6513 148.408 6.61797H146.939C146.849 6.06374 146.546 5.57397 146.03 5.14863C145.515 4.71041 144.819 4.4913 143.942 4.4913C143.13 4.4913 142.454 4.71041 141.912 5.14863C141.371 5.57397 141.1 6.16041 141.1 6.90797C141.1 7.47508 141.248 7.93263 141.545 8.28063C141.841 8.62863 142.209 8.89285 142.647 9.0733C143.098 9.25374 143.704 9.44708 144.464 9.6533C145.341 9.89819 146.05 10.1431 146.591 10.388C147.132 10.62 147.59 10.9809 147.964 11.4706C148.337 11.9604 148.524 12.6177 148.524 13.4426C148.524 14.0742 148.357 14.6735 148.022 15.2406C147.686 15.8077 147.19 16.2653 146.533 16.6133C145.876 16.9613 145.089 17.1353 144.174 17.1353Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_bd_280_2557"
          x="-62.246"
          y="-76.6687"
          width="290.77"
          height="173.804"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="40" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_280_2557"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="16" />
          <feGaussianBlur stdDeviation="9.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_280_2557"
            result="effect2_dropShadow_280_2557"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_280_2557"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default NameOnly;
