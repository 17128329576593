import { init } from "@fullstory/browser";

export default function initializeFullStory({
  devMode = import.meta.env.DEV,
} = {}): any {
  init({
    orgId:
      import.meta.env.VITE_APP_FULLSTORY_ORG_ID || "dummy-fullstory-org-id",
    devMode,
  });
}
