import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import { useCallback } from "react";
import toastUtils, { resolveValue } from "react-hot-toast";

import Spinner from "components/Spinner";

function Toast(props) {
  const { id, type, message, visible, ariaProps, dismissable } = props;
  const onDismiss = useCallback(() => toastUtils.dismiss(id), [id]);

  const Icon = iconByType[type];
  return (
    <Transition
      appear
      show={visible}
      enter="transition-transform transform duration-200"
      enterFrom="translate-y-full"
      enterTo="translate-y-0"
      leave="transition-opacity duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={classNames(
          "w-[calc(100vw-2rem)] rounded-md p-4 shadow-md transition-colors sm:w-120",
          containerClassesByType[type]
        )}
        {...ariaProps}
      >
        <div className="flex items-center">
          <div className="shrink-0">
            <Icon className={iconClassesByType[type]} aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p
              className={classNames(
                "text-sm font-medium",
                messageClassesByType[type]
              )}
            >
              {resolveValue(message, props)}
            </p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              {dismissable && (
                <button
                  type="button"
                  className={classNames(
                    "inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2",
                    buttonClassesByType[type]
                  )}
                  onClick={onDismiss}
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}

const iconByType = {
  success: CheckCircleIcon,
  error: ExclamationCircleIcon,
  loading: Spinner,
};

const buttonClassesByType = {
  success:
    "bg-green-50 dark:bg-transparent text-green-500 dark:text-green-400 hover:bg-green-100 dark:hover:bg-green-800/40 focus:ring-offset-green-50 focus:ring-green-600",
  error:
    "bg-red-50 dark:bg-transparent text-red-500 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-800/40 focus:ring-offset-red-50 focus:ring-red-600",
  loading:
    "bg-gray-50 dark:bg-transparent text-gray-500 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-600/40 focus:ring-offset-gray-50 focus:ring-gray-600",
};

const containerClassesByType = {
  success: "bg-green-50 dark:bg-green-900",
  error: "bg-red-50 dark:bg-red-900",
  loading: "bg-gray-50 dark:bg-gray-800",
};

const messageClassesByType = {
  success: "text-green-800 dark:text-green-400",
  error: "text-red-800 dark:text-red-400",
  loading: "text-gray-800 dark:text-gray-100",
};

const iconClassesByType = {
  success: "h-5 w-5 text-green-400",
  error: "h-5 w-5 text-red-400",
  loading: "h-4 w-4 mx-0.5 text-gray-600 dark:text-gray-300",
};

Toast.defaultProps = {
  dismissable: true,
};

export default Toast;
