import { ReactNode } from "react";

import { ToasterSafeArea } from "components/Toaster";

interface Props {
  children: ReactNode;
}

function Footer({ children }: Props) {
  return (
    <ToasterSafeArea className="flex w-full flex-col border-t p-4 dark:border-gray-800 dark:bg-gray-900">
      {children}
    </ToasterSafeArea>
  );
}

export default Footer;
