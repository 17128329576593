import { useContext, createContext, useMemo, useState } from "react";
import { Toaster as HotToastToaster } from "react-hot-toast";

import Toast from "./Toast";

const ToasterOffset = createContext(() => {});

export function useToasterOffset() {
  return useContext(ToasterOffset);
}

function Toaster({ children }) {
  const [offset, setOffset] = useState(null);

  const containerStyle = useMemo(
    () => (offset != null ? { bottom: offset } : undefined),
    [offset]
  );

  return (
    <ToasterOffset.Provider value={setOffset}>
      {children}
      <HotToastToaster position="bottom-center" containerStyle={containerStyle}>
        {(toastProps) => <Toast {...toastProps} />}
      </HotToastToaster>
    </ToasterOffset.Provider>
  );
}

export default Toaster;
