import { Logo, NameOnly } from "components/Logo";

function Loading({ children }) {
  return (
    <div className="flex h-[100dvh] flex-col items-center justify-center p-4">
      <Logo className="h-14" />
      <div className="mt-4 text-lg font-medium">{children || <NameOnly />}</div>
    </div>
  );
}

export default Loading;
