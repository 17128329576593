import { Suspense, lazy } from "react";

import FullPageLoader from "components/FullPageLoader";

const TmsApp = lazy(() => import("./TmsApp"));

function LazyTmsApp() {
  return (
    <Suspense
      fallback={
        <div className="h-screen">
          <FullPageLoader />
        </div>
      }
    >
      <TmsApp />
    </Suspense>
  );
}

export default LazyTmsApp;
