function Logo({ className }) {
  return (
    <svg
      className={className}
      data-testid="toro-logo"
      viewBox="0 0 52 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Toro TMS</title>
      <g clipPath="url(#clip0_7_22)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 15V16.8893C10 18.1555 10.7949 19.2853 11.9866 19.713L12.7872 20.0003C13.665 20.3153 14.3464 21.0207 14.6308 21.9089L18.1558 32.915C18.5537 34.1572 19.7085 35 21.0129 35H25.746H26.254H30.9871C32.2915 35 33.4463 34.1572 33.8442 32.915L37.3692 21.9089C37.6536 21.0207 38.335 20.3153 39.2128 20.0003L40.0134 19.713C41.2051 19.2853 42 18.1555 42 16.8893V15L36.4435 16.9941C35.5561 17.3126 34.87 18.0298 34.5911 18.9306L31.5085 28.8872C31.1196 30.1435 29.9578 31 28.6427 31H26.254H25.746H23.3573C22.0422 31 20.8804 30.1435 20.4915 28.8872L17.4089 18.9306C17.13 18.0298 16.4439 17.3126 15.5565 16.9941L10 15Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.662542 0L0.103006 3.23977C-0.214777 5.07975 0.209437 6.9707 1.28277 8.4986C2.65744 10.4555 4.92941 11.5832 7.31923 11.495L20.7215 11H20.7216H31.2789H31.279L44.6813 11.495C47.0711 11.5832 49.3431 10.4555 50.7178 8.4986C51.7911 6.9707 52.2153 5.07975 51.8975 3.23977L51.338 0L50.6521 2.75893C50.3027 4.16423 49.4696 5.40159 48.2989 6.25387C47.1896 7.06145 45.8424 7.47568 44.4711 7.43088L31.279 7H31.2789H20.7216H20.7215L7.5295 7.43088C6.1581 7.47568 4.81098 7.06145 3.70168 6.25387C2.53098 5.40159 1.69783 4.16423 1.34845 2.75893L0.662542 0Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_7_22">
          <rect width="100%" height="100%" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;
