import { Logo, NameOnly } from "components/Logo";

import Page from "./Page";

function Loading({ children }) {
  return (
    <Page className="relative overflow-hidden">
      <div className="absolute h-full w-20 animate-scan-left-to-right bg-gradient-to-r from-gray-700/0 via-gray-700/10 to-gray-700/0" />

      <div className="relative z-1 flex h-full flex-col items-center justify-center px-6 text-gray-600">
        <Logo className="h-14" />
        <div className="mt-4 text-lg font-medium">
          {children || <NameOnly />}
        </div>
      </div>
    </Page>
  );
}

export default Loading;
