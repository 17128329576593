import { ChevronLeftIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router-dom";

interface BackLink {
  to: string;
  label?: string;
}

interface BackButton {
  onClick: () => void;
  label?: string;
}

type Back = BackLink | BackButton;

interface Props {
  back?: Back;
  title?: string;
}

interface LinkProps {
  back: BackLink;
  className?: string;
}

interface ButtonProps {
  back: BackButton;
  className?: string;
}

const Button = ({
  back,
  className,
  children,
}: PropsWithChildren<ButtonProps>) => (
  <button type="button" className={className} onClick={back.onClick}>
    {children}
  </button>
);

const Link = ({ back, className, children }: PropsWithChildren<LinkProps>) => (
  <RouterLink className={className} to={back.to}>
    {children}
  </RouterLink>
);

function Header({ back, title }: Props) {
  const backClassName =
    "inline-flex items-center gap-x-2 focus:outline-none focus:ring-2 rounded-md p-2 -m-2";
  const backChildren = (
    <>
      <div className="rounded-lg border border-gray-600 bg-gray-800 p-1.5">
        <ChevronLeftIcon className="h-5 w-5 font-bold" />
      </div>
      <span
        className={classNames("text-xs text-gray-200", {
          "sr-only": !back?.label,
        })}
      >
        {back?.label || "Go back"}
      </span>
    </>
  );

  return (
    <div className="space-y-1 p-4 dark:bg-gray-900">
      {back &&
        ("onClick" in back ? (
          <Button className={backClassName} back={back}>
            {backChildren}
          </Button>
        ) : (
          <Link back={back} className={backClassName}>
            {backChildren}
          </Link>
        ))}

      {title && <h1 className="text-lg font-medium">{title}</h1>}
    </div>
  );
}

export default Header;
