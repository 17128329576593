import { Logo } from "components/Logo";
import Spinner from "components/Spinner";

function FullPageLoader({ message = "Loading..." }) {
  return (
    <div
      className="flex h-full items-center justify-center"
      data-testid="full-page-loader"
    >
      <div className="dark:text-300 flex flex-col items-center text-gray-400">
        <div>
          <Logo className="h-9" />
        </div>
        <div className="mt-2 flex items-center">
          <p className="mr-2 font-semibold">{message}</p>
          <Spinner className="w-5" />
        </div>
      </div>
    </div>
  );
}

export default FullPageLoader;
