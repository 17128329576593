import { ReactNode } from "react";

import { ToasterSafeArea } from "components/Toaster";

interface Props {
  children: ReactNode;
}

function BottomPanel({ children }: Props) {
  return (
    <ToasterSafeArea className="rounded-t-lg p-4 dark:bg-gray-900">
      {children}
    </ToasterSafeArea>
  );
}

export default BottomPanel;
