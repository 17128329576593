import { useRef, useState, useLayoutEffect } from "react";

// Modeled after https://usehooks-ts.com/react-hook/use-element-size
function useElementSize() {
  const ref = useRef();
  const [size, setSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const dimensions = entries?.[0].contentBoxSize?.[0];
      if (dimensions) {
        setSize({
          width: dimensions.inlineSize || 0,
          height: dimensions.blockSize || 0,
        });
      }
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [setSize]);

  return [ref, size];
}

export default useElementSize;
