import { useEffect } from "react";

import { useToasterOffset } from "components/Toaster";
import useElementSize from "hooks/useElementSize";

function ToasterSafeArea({ children, className, padding = 4 }) {
  const setToasterOffset = useToasterOffset();
  const [ref, { height }] = useElementSize();

  useEffect(() => {
    setToasterOffset(height + padding);
    return () => setToasterOffset(null);
  }, [setToasterOffset, height, padding]);

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
}

export default ToasterSafeArea;
