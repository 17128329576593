import { lazy, Suspense } from "react";

import { ThemeProvider } from "components/App/ThemeProvider";

import Loading from "./Loading";

const DriverApp = lazy(() => import("./DriverApp"));

function LazyDriverApp() {
  return (
    <ThemeProvider forceDark>
      <Suspense fallback={<Loading />}>
        <DriverApp />
      </Suspense>
    </ThemeProvider>
  );
}

export default LazyDriverApp;
