import BottomPanel from "./BottomPanel";
import Container from "./Container";
import Footer from "./Footer";
import Header from "./Header";

const Page = Object.assign(Container, {
  Header: Header,
  Footer: Footer,
  BottomPanel: BottomPanel,
});

export default Page;
