import classNames from "classnames";
import { ReactNode } from "react";

interface Props {
  color: "dark" | "light";
  className?: string;
  children: ReactNode;
  header: ReactNode;
  footer: ReactNode;
}

function Container({
  children,
  className,
  color = "dark",
  header = null,
  footer = null,
}: Props) {
  return (
    <div
      className={classNames(
        "flex min-h-[100dvh] text-gray-900 dark:bg-gray-900 dark:text-white",
        className
      )}
    >
      <div
        className={classNames(
          "mx-auto flex w-screen max-w-lg flex-1 flex-col",
          { "dark:bg-gray-900": color === "dark" },
          { "dark:bg-gray-800": color === "light" }
        )}
      >
        {header && <header className="sticky top-0 z-1">{header}</header>}
        <main className="h-0 w-full flex-1">{children}</main>
        {footer && <footer className="sticky bottom-0">{footer}</footer>}
      </div>
    </div>
  );
}

export default Container;
