import { lazy, Suspense } from "react";

import Loading from "./Loading";

const CustomerApp = lazy(() => import("./CustomerApp"));

function LazyCustomerApp() {
  return (
    <Suspense fallback={<Loading />}>
      <CustomerApp />
    </Suspense>
  );
}

export default LazyCustomerApp;
