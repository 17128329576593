import classNames from "classnames";
import { createContext } from "react";

import useLocalStorage from "hooks/useLocalStorage";

export const ThemeContext = createContext({ darkModeEnabled: false });

export function ThemeProvider({ children, forceDark = false }) {
  const [darkModeEnabled, setDarkModeEnabled] = useLocalStorage(
    "torotms_enableDarkMode",
    false
  );

  const dark = darkModeEnabled || forceDark;

  return (
    <ThemeContext.Provider
      value={{ darkModeEnabled: dark, setDarkModeEnabled }}
    >
      <div data-testid="dark-mode-root" className={classNames({ dark })}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
}
