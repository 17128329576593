import { FullStory } from "@fullstory/browser";
import { fullStoryIntegration } from "@sentry/fullstory";
import * as Sentry from "@sentry/react";

const TRACES_SAMPLE_RATE = 0.4;
const IGNORE_ERRORS = [
  "Illegal invocation",
  "ResizeObserver loop limit exceeded",
  /Non-Error promise rejection captured with value: Object Not Found Matching/,
  "There is no source with this ID",
];

function initializeSentry(history: History): void {
  if (import.meta.env.DEV) {
    return;
  }

  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_SENTRY_ENV,
    release: import.meta.env.VITE_APP_COMMIT_REF,
    ignoreErrors: IGNORE_ERRORS,
    normalizeDepth: 5,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      fullStoryIntegration(import.meta.env.VITE_APP_SENTRY_ORG!, {
        client: FullStory,
      }),
    ],
    tracesSampleRate: TRACES_SAMPLE_RATE,
  });
}

export default initializeSentry;
