import { useState } from "react";

/**
 * Copied directly from https://usehooks.com/useLocalStorage/
 * @param {string} key localStorage key for storing the value
 * @param {any} initialValue value to store in localStorage the first time the hook is ever called
 * @returns [value, setValue] just like the useState hook
 */
function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export default useLocalStorage;
